export const ACCOUNT_TYPES = {
  subscription: 1,
  aLaCarte: 2,
  freeTrial: 3,
  freemium: 4,
  peaceOfMind: 6,
  freedomToOperate: 7,
  allAccess: 8,
  training: 10,
  trainingPlus: 11,
};

export const MAX_FILE_SIZE = (mimeType = '') => {
  // for regular files, the default size is 100MB
  let size = 100;

  // for images, the default size is 5MB
  if (mimeType.includes('image')) {
    size = 5;
  }
  return size * 1024 * 1024;
};

export const ONE_LIFE_ACCOUNT = {
  FIRST_NAME: '1life',
};

export const DATE_SELECTOR_RANGE = {
  MIN: '1900-01-01',
  MAX: '2100-01-01',
  // Used when the number is inclusive, and must exclude the minimum/maximum
  // date. For example when using a v-date-picker's :max property
  MIN_EXCLUSIVE: '1899-12-31',
  MAX_EXCLUSIVE: '2099-12-31',
};

export const CORRECTIVE_ACTION_STATUS = {
  RESOLVED: 1,
  UNRESOLVED: 2,
};

export const USER_TYPES = {
  NORMAL_USER: 0,
  WEB_ADMIN: 1,
  INTERNAL_ADMIN: 2,
};

export const PAGINATION = {
  DEFAULT_PAGE: 1,
  DEFAULT_PERPAGE: 20,
  DEFAULT_FOOTER_PROPS: {
    'items-per-page-options': [20, 30, 50],
  },
};

export const EMPLOYEE_DETAIL_COLUMN_NAMES = {
  FIRST_NAME: 'First_Name',
  LAST_NAME: 'Last_Name',
  USERNAME: 'Username',
  EMAIL: 'Email',
  STATUS: 'Status',
  WEB_ACCESS: 'isWebAppEnabled',
  WEB_ADMIN: 'isWebAdministrator',
  MOBILE_ACCESS: 'isMySafetyAppEnabled',
  SUPERVISOR: 'isSupervisor',
  MANAGER: 'isManager',
};

export const EXCEL_PASSWORD = 'yc$GH6s4C-gdA9}8';

// 6 characters with at least one upper case, one lower case, one number
export const PASSWORD_VALIDATION_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/;

export const PRIVATE_DOCUMENT_LIBRARY_FILTERS = {
  search: '',
  groupIds: [],
  createdFrom: '',
  createdTo: '',
  expiredFrom: '',
  expiredTo: '',
  docTypes: [],
  isPublished: 2,
  deleted: 1,
};

export const FREEMIUM_LICENSE_LIMIT = 6;

export const TRAINING_RECORD_LIBRARY_FILTERS = {
  completedFrom: '',
  completedTo: '',
  recertificationFrom: '',
  recertificationTo: '',
  publish: 2,
  showDeleted: 1,
  search: '',
  employeeStatus: 1,
  userIds: [],
  hasEmployeeAttached: 2,
  groupIds: [],
  filterApplied: 0,
  filename: '',
};

export const TRAINING_RECORDS_REPORT_FILTER = {
  userIds: [],
  employeeStatus: 1,
  labels: [],
  supervisors: [],
  documentName: '',
  documentGroupIds: [],
  assignedByUserIds: [],
  completedFrom: '',
  completedTo: '',
  recertificationFrom: '',
  recertificationTo: '',
  hasUserAttached: 2,
};

export const TASK_TEMPLATE_FILTER = {
  taskName: '',
  groupName: '',
  taskTypeIds: [],
  deadlineFrom: '',
  deadlineTo: '',
};

export const TASK_FILTER = {
  taskName: '',
  groupName: '',
  taskStatusIds: [1, 2, 3],
  dueDateFrom: '',
  dueDateTo: '',
  completedFrom: '',
  completedTo: '',
  taskTypeIds: [],
};

export const TASK_REPORT_FILTER = {
  userIds: [],
  labelIds: [],
  taskStatusIds: [1, 2, 3],
  taskName: '',
  dueDateFrom: '',
  dueDateTo: '',
  taskTypeIds: [],
};

export const FORM_TAGS_FILTER = {
  name: '',
  archived: 0,
  tagGroupIds: [],
};

export const EMPLOYEE_LIST_FILTERS = {
  users: [],
  search: '',
  labels: [],
  emails: [],
  supervisors: [],
  startDate: '',
  endDate: '',
  startDateUTC: '',
  endDateUTC: '',
  status: 1,
  isWebAdministrator: 2,
  isSupervisor: 2,
  isManager: 2,
  isSafetyRep: 2,
  createdFrom: '',
  createdTo: '',
};

export const BINARY_TYPES = {
  IMAGE: 1,
  CA_IMAGE: 2,
  FORM_SIGNATURE: 3,
  DOCUMENT_SIGNATURE: 4,
};

export const SIGNATURE_TYPES = {
  CREATED_BY: 1,
  SUPERVISOR: 2,
  MANAGER: 5,
  SAFETY_REP: 3,
  ADDITIONAL: 4,
};

export const FORM_ELEMENT_TYPES = {
  INSPECTION_ITEM: 1,
  TEXT_FIELD: 2,
  CHECKBOX: 3,
  YES_NO: 4,
  COMMON_HAZARDS: 5,
  INSTRUCTIONS: 6,
  DROP_DOWN_SINGLE: 7,
  DROP_DOWN_MULTI: 8,
  DATE: 9,
  TIME: 10,
  PDF: 11,
  IMAGE: 12,
};

export const FORM_INSTANCE_STATUS = {
  DRAFT: 1,
  SUBMITTED: 2,
  FINALIZED: 3,
  REVIEWED: 4,
  ASSIGNED: 5,
};

export const FORM_INSTANCE_DEFAULT_FILTERS = {
  requiringAttentionUserIds: [],
  title: '',
  templateName: '',
  projectIds: [],
  createdByIds: [],
  createdFrom: '',
  createdTo: '',
  assignedToIds: [],
  signedByUserIds: [],
  signedByContactIds: [],
  status: [2, 3, 4],
  isPrivate: 2,
  pastSyncCutOff: 2,
  hasFormAudit: 2,
  hasCorrectiveActions: 2,
  correctiveActionStatus: [1, 2],
  correctiveActionAssignedToIds: [],
  tagIds: [],
  requiringMyAttention: 0,
  supervisorIds: [],
};

export const FILTER_INTERVALS = {
  ALL_TIME: 1,
  CUSTOM: 2,
  TODAY: 3,
  YESTERDAY: 4,
  THIS_WEEK: 5,
  LAST_WEEK: 6,
  LAST_7_DAYS: 7,
  LAST_14_DAYS: 8,
  THIS_MONTH: 9,
  LAST_MONTH: 10,
  LAST_30_DAYS: 11,
  THIS_QUARTER: 12,
  LAST_QUARTER: 13,
};

export const COMPANY_FORM_FILTERS = {
  status: [],
  isPrivate: 0,
  pastSyncCutOff: 0,
  hasFormAudit: 0,
  hasCorrectiveActions: 2,
  correctiveActionStatus: 2,
  title: [],
  users: [],
  templateIds: [],
  projectIds: [],
  createdByIds: [],
  createdFrom: '',
  createdTo: '',
  assignedToIds: [],
  signedOffByIds: [],
  signedOffByContactIds: [],
  correctiveActionAssignedToIds: [],
  tagIds: [],
  createdDateIntervalId: 1,
  completedByIds: [],
};

export const FORM_INSTANCE_SECTION_TYPES = {
  REGULAR: 0,
  ADDITIONAL_HAZARD: 1,
};

export const TASK_TYPES = { REGULAR: 1, DOCUMENT: 2, TRAINING: 3 };

export const ASSIGNED_DOCUMENT_STATUS = {
  ASSIGNED: 2,
  SUBMITTED: 3,
};

export const ASSIGNED_DOCUMENTS_DEFAULT_FILTERS = {
  title: '',
  createdOnMobileBy: [],
  createdFrom: '',
  createdTo: '',
  completedFrom: '',
  completedTo: '',
  label: [],
  status: [2, 3],
  signedOffByContacts: [],
  projectIds: [],
  requiringMyAttention: 0,
  supervisorIds: [],
};

export const ASSIGNED_FORMS_DEFAULT_FILTERS = {
  templateName: '',
  createdFrom: '',
  createdTo: '',
  assignedTo: [],
  includeArchived: 0,
};

export const PROJECTS_DEFAULT_FILTERS = {
  name: '',
  address: '',
  startFrom: '',
  startTo: '',
  endFrom: '',
  endTo: '',
  status: 1,
};

export const CONTACTS_DEFAULT_FILTERS = {
  name: '',
  email: '',
  companyName: '',
  jobTitle: '',
  status: 1,
};

export const COMPANY_LIST_DEFAULT_FILTERS = {
  search: '',
  organizations: [],
  status: 1,
  accountTypes: [],
  reportRange: '1m',
  testAccount: 2,
};

export const SUPPORT_LINKS = {
  KNOWLEDGE_BASE: 'https://knowledgebase.1lifesoftware.com/',
  GET_LIVE_TRAINING: 'https://calendly.com/1lifesupport',
  WHATS_NEW:
    'https://1lifewss.com/mysafetyassistant/mysafetyassistant-release-notes/',
  CONTACT_US: 'https://1lifewss.com/contact-support/',
};

export const EMPLOYEE_NOTES_DEFAULT_FILTERS = {
  name: '',
  comments: '',
  createdFrom: '',
  createdTo: '',
  createdByIds: [],
};

export const SUBSCRIPTION_TERM_TYPES = {
  YEARLY: 1,
  MONTHLY: 2,
  'N/A': 3,
};

export const SESSION_TIMER_CONFIG = {
  enabled: false,
  count: 0,
  display: '00:00',
  warning: 60 * 2,
  timeout: 60 * 60,
  timestamp: 0,
};

export const MASS_ARCHIVE_FORMS_FILTERS = {
  title: '',
  projectIds: [],
  createdFrom: '',
  createdTo: '',
  showArchived: 0,
};

export const USER_LIST_DEFAULT_FILTERS = {
  accountTypes: [],
  status: 1,
  phone: '',
  isWebAdmin: 2,
  isSupervisor: 2,
  isManager: 2,
  isSafetyRep: 2,
};

export const AUDITS_REPORT_FILTERS = {
  companyId: 0,
  companyIds: [],
  companySubscriptionTypes: [],
  companyStatus: 1,
  userStatus: 1,
  isWebAdmin: 2,
  isSupervisor: 2,
  isManager: 2,
  isSafetyRep: 2,
  isGroupAdmin: 2,
  from: '',
  to: '',
  documentName: '',
  documentGroupName: '',
};

export const SYNCHRONIZATION_CUTOFF_DAYS = 8;

export const SCHEDULE_USERS_FILTERS = {
  showDeleted: 0,
  search: '',
};

export const CORRECTIVE_ACTION_REPORT_DEFAULT_FILTERS = () => ({
  formLabel: '',
  assignedTo: [],
  createdFrom: '',
  createdTo: '',
  resolvedTo: '',
  resolvedFrom: '',
  searchHazard: '',
  formStatus: [
    FORM_INSTANCE_STATUS.SUBMITTED,
    FORM_INSTANCE_STATUS.FINALIZED,
    FORM_INSTANCE_STATUS.REVIEWED,
  ],
  correctiveActionStatus: [1, 2],
  formAuditStatus: 2,
  userStatus: 1,
});

export const FORM_ELEMENT_STATUS = {
  SATISFACTORY: 1,
  UNSATISFACTORY: 2,
  NOT_APPLICABLE: 3,
};

export const MAX_FILENAME_LENGTH = 255;

export const ACCEPTED_MIME_TYPES = {
  IMAGE: ['image/jpeg', 'image/png', 'image/gif'],
  PDF: ['application/pdf'],
  EXCEL: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  WORD: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
};
